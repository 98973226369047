.container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #262626;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 70vw;
}

.error-image {
  top: -30px;
  height: 60px;
}

.error-message {
  color: red;
}

.debug-hidden-message {
  color: transparent;
}

h1 {
  color: black;
}

.logo {
  z-index: 10;
  top: 10px;
  left: 10px;
  width: 200px !important;
  position: fixed;
}
