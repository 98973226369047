.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #262626;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding: 10px;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.btn {
    font-size: 16px;
    margin: 5px;
    height: 50px;
    border-radius: 10px;
    width: 100%;
    border: none;
    background-color: transparent;
    color: white;
    max-width: 720px;
}

.btn-outlined{
    background-color: transparent;
    color: white;
    border: 1px solid white;
}
.btn:hover {
    box-shadow: 0px 2px 0 0 rgb(148, 148, 148);
}

.bg-warning {
    color: black;
    background-color: rgb(226, 226, 0);
}

.bg-success {
    color: white;
    background-color: rgb(0, 163, 0);
}

.logo {
    width: 360px;
}

.sigContainer {
    width: 95vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 720px;
}

.sigCanvas {
    width: 100%;
    height: 200px;
    background-color: white;
    border-radius: 10px;
    margin: 5px;
}

.name-input {
    max-height: 50px;
    height: 50px;
    border-radius: 10px;
    width: 100%;
    font-size: 16px;
    border: none;

    text-indent: 10px;
}

.thanks {
    padding: 50px;
}

.alert {
    border-radius: 10px;
    max-height: 100px;
    line-indent: 10px;
    width: 100%;
    max-width: 95vw;
    margin: 5px;
    text-align: center;
    align-items: center;
}

.alert-danger {
    border: 1px dashed red;
    color: red;
    background-color: rgba(255, 0, 0, 0.265);
}

.vSpacer {
    margin-left: 5px;
}

.submit-button {
    background: linear-gradient(to bottom, #2FC703 0, #2a9903 100%);
}